// General
import React, { ReactElement, useEffect, useState } from "react";
// Components
import { CardContent, CardTitle } from "@msi/cobalt-react/card";
// Styles
import "./collapsiblePanel.module.css";
// Store
import { BehaviorSubject } from "rxjs";

export interface CollapsiblePanelProps {
  icon?: ReactElement;
  content?: unknown;
  name?: string;
  hidden?: boolean | BehaviorSubject<boolean>;
  columnClass?: string;
  disabled?: boolean;
  scrollable?: boolean;
}

const CollapsiblePanel = (props: CollapsiblePanelProps): JSX.Element => {
  const { icon, content, hidden, columnClass, name, disabled, scrollable } =
    props;
  const [isHidden, setIsHidden] = useState(false);
  const [currentColumnClass, setCurrentColumnClass] = useState(
    isHidden ? null : columnClass
  );
  const cardStyle = {
    color: "var(--lightGrey)",
    backgroundColor: "var(--lightGrey)",
    flex: "nowrap",
    overflow: scrollable ? "auto" : "hidden",
  };

  useEffect(() => {
    let isHiddenSubscription;
    if (hidden instanceof BehaviorSubject) {
      isHiddenSubscription = hidden.subscribe((value) => {
        if (value != null) {
          setIsHidden(value);
          setCurrentColumnClass(value ? null : columnClass);
        }
      });
    } else {
      setIsHidden(hidden);
      setCurrentColumnClass(hidden ? null : columnClass);
    }

    return function cleanup() {
      isHiddenSubscription?.unsubscribe();
    };
  }, [hidden]);

  const setCardHidden = () => {
    if (hidden instanceof BehaviorSubject) {
      hidden?.next(!hidden?.value);
    } else {
      setIsHidden(!isHidden);
    }
    if (currentColumnClass === null) {
      setCurrentColumnClass(columnClass);
    } else {
      setCurrentColumnClass(null);
    }
  };

  const getColumnClass = () => {
    return currentColumnClass ?? "";
  };

  const getDisabled = () => {
    return `${disabled ? "compass-disabled-div" : ""}`;
  };

  return (
    <div
      className={`card collapsible-panel ${getColumnClass()} ${getDisabled()}`}
      style={cardStyle}
      data-cy="collapsible-panel-card"
    >
      <CardTitle>
        <i
          data-cy="card-icon"
          aria-hidden="true"
          onClick={setCardHidden}
          style={{ paddingBottom: "6px" }}
        >
          {icon}
        </i>
      </CardTitle>
      <div
        className={`collapsible-panel-content-div${isHidden ? "-hidden" : ""}`}
        data-cy={`collapsible-panel-content${isHidden ? "-hidden" : ""}`}
        id={name}
      >
        <CardContent>{content}</CardContent>
      </div>
    </div>
  );
};

CollapsiblePanel.defaultProps = {
  content: <p>Empty Panel</p>,
  name: "Collapsible Panel",
  hidden: true,
  columnClass: null,
  disabled: false,
  scrollable: true,
};

export default CollapsiblePanel;
